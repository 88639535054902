//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import {parser} from "@/utils/parser";

export default {
  name: "SectionNews",
  components: {
    Section: () => import("@/components/common/Section/index"),
    NewsCard: () => import("@/components/common/NewsCard/index"),
    Scroller: () => import("@/components/common/Scroller/index"),
    SliderWithBullets: () => import("@/components/common/SliderWithBullets"),
  },
  props: {
    disableMarginTop: {
      type: Boolean,
      default: () => false
    },
    disableMarginBottom: {
      type: Boolean,
      default: () => false
    },
    query: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      news: [],
      show: false,
      pending: true,
    };
  },
  computed: {
    ...mapGetters({
      city: "cities/default",
    }),
    defaultQuery() {
      return {
        city_id: this.$config.singleCity ? this.city.id : null,
        count: 15,
        resolution: "micro",
        lang: this.$i18n.locale
      }
    },
    queryParams() {
      return {
        ...this.defaultQuery,
        ...this.query
      };
    }
  },
  mounted() {
    this.$axios.$get(`/api/news?${parser({
      params: this.queryParams
    })}`)
      .then(response => {
        this.news = response.data || [];
      })
      .catch(() => null)
      .finally(() => {
        this.show = this.news.length > 0;
      });
  },
};
